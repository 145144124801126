.select-full {
    width: 100%;
}

.radio {
    margin-right: 5px;
}

.is-not-visible {
    display: none;
}

.title-container {
    margin-bottom: 11px !important;
}

.title-hr {
    margin-top: 0px;
    margin-bottom: 15px;
}

.navbar-link:not(.is-arrowless)::after,
.select:not(.is-multiple):not(.is-loading)::after {
    border-left-width: 2px;
    border-bottom-width: 2px;
}

.buttons-group-tabs {
    margin-left: 5px;
}

.table th:not([align]) {
    text-align: left;
}

.button-new {
    font-weight: 500 !important;
}

.tag-custom {
    font-weight: 600;
}

.button-link-custom {
    background-color: #265D26 !important;
}

.tabs li.is-active a {
    border-bottom-color: #000;
    color: #000;
}

.button-tab {
    color: #000 !important;
    font-weight: 500 !important;
}

.node-snackbar-custom {
    border-radius: 15px !important;
    font-weight: bold !important;
    margin-left: 5px;
    margin-right: 5px;
}

@media (max-width: 640px) {
    .snackbar-container {
        margin-bottom: 3px;
        left: 0;
        right: 0;
        width: 100%;
    }
}

.button {
    border-radius: 0.8rem !important;
}

.button.is-small {
    border-radius: 0.6rem !important;
}

.input {
    border-radius: 0.7rem !important;
}

select {
    border-radius: 0.7rem !important;
}

textarea {
    border-radius: 0.7rem !important;
}